<template>
  <div class="pg">
        <Top  :topIndex="5"  @changeL="changeL"></Top>
        <div style="min-height:10vh">
            <img :src="cn_enobj.site.index.home_logo" class="zhanweiImg" alt="">
        </div>
        <div class="descBox-box2">
            <div  class="descBox-box2-btn" @click="comeback()">
                <img src="@/assets/img/返回.png"  alt="">
                <span>{{cn_enobj.site.components.bottom.back_bottom}}</span>
            </div>
        </div>
        <div class="descBox" v-html="locale == 'zh'?desc.content:desc.en_content"></div>
        <Bottom></Bottom>
        <ReturnTop></ReturnTop>
  </div>
</template>

<script>
import Top from '@/components/top.vue'
import Bottom from '@/components/bottom.vue'
import ReturnTop from '@/components/returnTop.vue'
import scrollReveal from 'scrollreveal'
export default {  
    components:{Top,Bottom,ReturnTop},
    data () {
        return {
            scrollReveal: scrollReveal(),
            desc: "<p>I believe I can fly</p>",
            detaileList:[
                {img:'img/05.jpg'},
                {img:'img/03.jpg'},
                {img:'img/07.jpg'},
                {img:'img/01.jpg'}
            ],
            locale:'',
            cn_enobj:{}
        }
    },
     //项目中执行，自定义类名。
    mounted() {
        this.scrollReveal.reveal('.reveal-bottom', {origin: 'bottom',...this.global});
        this.scrollReveal.reveal('.reveal-r', {...this.global,distance: '0px', scale: 0.9});
    },
    created(){
        let id = this.$route.query.id
        if(this.$route.query.type == 'glory'){ // 公司荣誉详情
            this.getDetail('/api/content/detail/glory?id='+id)
        }
        if(this.$route.query.type == 'press'){ // 新闻中心详情
            this.getDetail('/api/content/detail/press?id='+id)
        }
        if(this.$route.query.type == 'information'){ // 公司资讯详情
            this.getDetail('/api/content/detail/information?id='+id)
        }
        this.changeL(this.$i18n.locale)
    },
    methods: {
        changeL(str){
            this.locale = str
            this.cn_enobj = this.locale == 'zh' ?JSON.parse(sessionStorage.getItem("cnobj")):JSON.parse(sessionStorage.getItem("enobj"))
        },
        comeback(){
            this.$router.go(-1)
        },
        
        getDetail(url){
            this.$axios({
                url,
                method: 'get',
            }).then(res=>{
                res.data.data.en_content = res.data.data.en_content.replace(/\<img/gi,'<img class="imgnode"')
                res.data.data.content = res.data.data.content.replace(/\<img/gi,'<img class="imgnode"')
                this.desc = res.data.data
                console.log('大小',this.desc.content)
            })
        }
    }
}
</script>

<style lang="less">
.imgnode{
         width: 100%;
         height:auto;
    }
</style>

<style lang="less" scoped>
    .descBox{
        width: 56vw;
        min-height: 80vh;
        text-align: center;
        margin: 0 auto;
        word-break: keep-all;
        word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
        white-space: pre-wrap; //只对中文起作用，强制换行。
    }  

    .descBox-box2{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top:2vh;
    }
    .descBox-box2-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5vw;
        margin-right: 14vw;
        margin-bottom: 5vh;
        img{
            width: 30px;
            height: 30px;
            margin: 0 10px 0 0; 
        }
        span{
            color: #01AE0D;
            font-size: 2vmin;
        }
    }

    @media screen and (max-width:600px) {
        .descBox{
            width: 90vw;
            min-height: 80vh;
            text-align: center;
            margin: 0 auto;
            word-break: keep-all;
            word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
            white-space: pre-wrap; //只对中文起作用，强制换行。
            
        }   
        .descBox-box2{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top:2vh;
        }
        .descBox-box2-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 5vw;
            margin-right: 14vw;
            margin-bottom: 5vh;
            img{
                width: 30px;
                height: 30px;
                margin: 0 10px 0 0; 
            }
            span{
                color: #01AE0D;
                font-size: 2.4vmin;
            }
        }

    }
</style>